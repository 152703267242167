<template>
  <div class="problem-container">
    <v-title>
      <Breadcrumb>
        <Breadcrumb-item><i class="icon-home"></i>问题中心</Breadcrumb-item>
      </Breadcrumb>
    </v-title>
    <div class="content">
      <div class="search-box">
        <Row>
          <Col span="24">
            <h2 class="search-title border-bottom">按条件搜索</h2>
          </Col>
        </Row>
        <Form class="search-form" :label-width="85">
          <Row>
            <Col span="5">
            <Form-item label="上报日期：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="会员姓名：">
                <Input v-model="searchForm.memble_name" placeholder="请输入..."></Input>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="促进师：">
                <Cascader :data="booster" v-model="boosterArr"></Cascader>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="专家：">
                <Cascader :data="doctor" v-model="doctorArr"></Cascader>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="上报来源：">
                <Select clearable v-model="searchForm.source" placeholder="请选择">
                  <Option :value="v.name" v-for="v in sourceList" :key="v.name">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="负责部门：">
                <Select clearable v-model="searchForm.unit" placeholder="请选择">
                  <Option :value="v.id" v-for="v in unitList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="问题类型：">
                <Select clearable v-model="searchForm.type" placeholder="请选择">
                  <Option :value="v.id" v-for="v in problem_type_list" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <Row>
            <Col span="5">
              <Form-item label="省份：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
                <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                  <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="城市：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
                <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                  <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
            <Col span="1">&nbsp;</Col>
            <Col span="5">
              <Form-item label="门店：">
                <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
                <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                  <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
                </Select>
              </Form-item>
            </Col>
          </Row>
          <div class="tac">
            <Button type="primary" class="search-btn" @click="handleSubmit()">搜索</Button>
          </div>
        </Form>
      </div>
      <div class="filter-list-tpl">
        <Row>
          <Col span="16">
            请选择：
            <Radio-group v-model="searchForm.status" @on-change="changeStatus">
              <Radio label="0">全部</Radio>
              <Radio label="1">未分配</Radio>
              <Radio label="2">未完成</Radio>
              <Radio label="3">已完成</Radio>
            </Radio-group>
          </Col>
          <Col span="8">
            <a href="javascript:;" class="btn-allot" @click="distribute()" v-if="this.power.indexOf('问题中心--分配负责人的权限') > -1">分配负责人</a>
            <a href="javascript:;" class="btn-allot" @click="distributeAll()" v-if="this.power.indexOf('问题中心--分配负责人的权限') > -1">全部分配负责人</a>
          </Col>
        </Row>
        <Table stripe :columns="columns" :data="problemList" @on-selection-change="selectOne"></Table>
        <Row>
          <Col span="12">
          <div class="table-oprate">
            <Button @click="delProblem" v-if="this.power.indexOf('问题中心--删除问题记录的权限') > -1">删除</Button>
          </div>
          </Col>
          <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
          </Col>
        </Row>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
          </div>
        </div>
      </div>
    </div>
    <!--分配负责人modal-->
    <Modal v-model="fpmodal" title="分配负责人">
      <div class="slotbody">
        <p>选择分配部门</p>
        <Select v-model="distribution.unit_id" placeholder="请选择" @on-change="changeUnit">
          <Option :value="v.id" v-for="v in unitList" :key="v.id">{{v.name}}</Option>
        </Select>
        <p>选择负责人</p>
        <Select v-model="distribution.user_id" placeholder="请选择">
          <Option :value="v.id" v-for="v in unituserLsit" :key="v.id">{{v.name}}</Option>
        </Select>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okDistri">确定</Button>
        <Button @click="cancel">取消</Button>
      </div>
    </Modal>
    <!--分配负责人modal-->
    <Modal v-model="fpmodalAll" title="全部分配负责人">
      <div class="slotbody">
        <p>选择分配部门</p>
        <Select v-model="distribution.unit_id" placeholder="请选择" @on-change="changeUnit">
          <Option :value="v.id" v-for="v in unitList" :key="v.id">{{v.name}}</Option>
        </Select>
        <p>选择负责人</p>
        <Select v-model="distribution.user_id" placeholder="请选择">
          <Option :value="v.id" v-for="v in unituserLsit" :key="v.id">{{v.name}}</Option>
        </Select>
      </div>
      <div slot="footer" class="tac">
        <Button @click="okDistriAll">确定</Button>
        <Button @click="cancelAll">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script  type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import problemService from '@/services/problemService';
export default {
	components: {
		'v-title': vTitle,
	},
	data() {
		return {
			searchForm: {
				page: 1,
				size: 10,
				start_time: '',
				end_time: '',
				memble_name: '',
				booster: '',
				doctor: '',
				source: '',
				unit: '',
				type: '',
				status: '0',
				pid: '',
				cid: '',
				sid: '',
			},
			distributionFormAll: {
				start_time: '',
				end_time: '',
				memble_name: '',
				belong: '',
				booster: '',
				doctor: '',
				source: '',
				unit: '',
				type: '',
				pid: '',
				cid: '',
				sid: '',
				user_id: '',
				unit_id: '',
				status: '',
			},
			del: {
				problem_ids: '',
			},
			distribution: {
				problem_ids: '',
				unit_id: '',
				user_id: '',
			},
			booster: [],
			boosterArr: [],
			doctor: [],
			doctorArr: [],
			sourceList: [],
			unitList: [],
			unituserLsit: [],
			unit_user: {},
			problem_type_list: [],
			proList: [],
			cityList: [],
			city: {},
			store: {},
			storeList: [],
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '上报日期', key: 'create_time', align: 'center' },
				{ title: '顾客类型', key: 'new_member_type', align: 'center' },
				{ title: '上报来源', key: 'source', align: 'center' },
				{ title: '会员姓名', key: 'member_name', align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{ title: '联系电话', key: 'mobile', align: 'center' },
				{ title: '问题类型', key: 'problem_type', align: 'center' },
				{ title: '促进师', key: 'belong_booster_name', align: 'center' },
				{ title: '负责人', key: 'charge_name', align: 'center' },
				{ title: '状态', key: 'status_string', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (params.row.status_string === '已完成') {
							return h('div', [
								h(
									'a',
									{
										class: 'icon_eye',
										style: {
											margin: '0 10px',
										},
										attrs: {
											target: '_blank',
											href: '/#/base/problem/detail/' + this.problemList[params.index].id,
										},
									},
									'查看',
								),
							]);
						} else if (params.row.status_string === '未完成') {
							return h('div', [
								h(
									'a',
									{
										class: 'icon_eye',
										style: {
											margin: '0 10px',
										},
										attrs: {
											target: '_blank',
											href: '/#/base/problem/detail/' + this.problemList[params.index].id,
										},
									},
									'查看',
								),
								h(
									'a',
									{
										class: 'icon_edit',
										style: {
											margin: '0 10px',
										},
										attrs: {
											target: '_blank',
											href:
												'/#/base/problem/edit/' +
												this.problemList[params.index].member_id +
												'/' +
												this.problemList[params.index].id,
										},
									},
									'编辑',
								),
							]);
						} else if (params.row.status_string === '未分配') {
							return h('div', [
								h(
									'a',
									{
										class: 'icon_eye',
										style: {
											margin: '0 10px',
										},
										attrs: {
											target: '_blank',
											href: '/#/base/problem/detail/' + this.problemList[params.index].id,
										},
									},
									'查看',
								),
							]);
						}
					},
				},
			],
			problemList: [],
			pageTotal: 0,
			fpmodal: false,
			fpmodalAll: false,
			power: '',
		};
	},
	watch: {
		boosterArr() {
			this.searchForm.booster = this.boosterArr[1];
		},
		doctorArr() {
			this.searchForm.doctor = this.doctorArr[1];
		},
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getProblemList();
		problemService.getProblemSelect().then((data) => {
			this.booster = data.booster;
			this.sourceList = data.source;
			this.unitList = data.unit;
			this.unit_user = data.unit_user;
			this.problem_type_list = data.problem_type;
			this.doctor = data.doctor;
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getProblemList() {
			problemService.getProblemList(this.searchForm).then((data) => {
				this.problemList = data.list;
				if (data.cur_page > data.page_size && data.row_size !== 0) {
					this.pageTotal = data.row_size + 1;
				} else {
					this.pageTotal = data.row_size;
				}
				this.del.problem_ids = '';
				this.distribution.problem_ids = '';
			});
		},
		handleSubmit() {
			this.distributionFormAll.start_time = this.searchForm.start_time;
			this.distributionFormAll.end_time = this.searchForm.end_time;
			this.distributionFormAll.memble_name = this.searchForm.memble_name;
			this.distributionFormAll.belong = this.searchForm.belong;
			this.distributionFormAll.doctor = this.searchForm.doctor;
			this.distributionFormAll.source = this.searchForm.source;
			this.distributionFormAll.unit = this.searchForm.unit;
			this.distributionFormAll.type = this.searchForm.type;
			this.searchForm.page = 1;
			this.getProblemList();
		},
		changeTime(date) {
			this.searchForm.start_time = date[0];
			this.searchForm.end_time = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getProblemList();
		},
		changeStatus() {
			this.distributionFormAll.status = this.searchForm.status;
			this.searchForm.page = 1;
			this.getProblemList();
		},
		selectOne(selection) {
			let arrId = [];
			let arrStatus = [];
			for (var i = 0; i < selection.length; i++) {
				arrId.push(selection[i].id);
				arrStatus.push(selection[i].status);
			}
			this.del.problem_ids = this.distribution.problem_ids = arrId.join(',');
			this.status_ids = arrStatus.join(',');
		},
		delProblem() {
			if (!this.del.problem_ids) {
				this.$Message.warning('请先选择要删除的问题');
			} else {
				this.$Modal.confirm({
					title: '确认',
					content: '确定删除所选问题吗',
					onOk: () => {
						this.okdel();
					},
				});
			}
		},
		okdel() {
			problemService.deleteProblem(this.del).then(() => {
				this.getProblemList();
			});
		},
		distribute() {
			if (this.distribution.problem_ids) {
				if (this.status_ids.indexOf('2') >= 0 || this.status_ids.indexOf('3') >= 0) {
					this.$Message.warning('请选择未分配的问题');
				} else {
					this.fpmodal = true;
				}
			} else {
				this.$Message.warning('请先至少选择一个问题');
			}
		},
		distributeAll() {
			this.fpmodalAll = true;
		},
		changeUnit() {
			this.unituserLsit = this.unit_user[this.distribution.unit_id];
		},
		okDistri() {
			if (!this.distribution.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				problemService.distribute(this.distribution).then(() => {
					this.getProblemList();
					this.fpmodal = false;
				});
			}
		},
		cancel() {
			this.fpmodal = false;
		},
		okDistriAll() {
			this.distributionFormAll.user_id = this.distribution.user_id;
			this.distributionFormAll.unit_id = this.distribution.unit_id;
			if (!this.distribution.user_id) {
				this.$Message.warning('请进行具体分配');
			} else {
				problemService.distributeAll(this.distributionFormAll).then(() => {
					this.getProblemList();
					this.fpmodalAll = false;
				});
			}
		},
		cancelAll() {
			this.fpmodalAll = false;
		},
	},
};
</script>

<style lang="css">
.problem-container .problem-list {
  background-color: #fff;
  padding: 30px 12px;
}

.problem-container .problem-list .ivu-radio-wrapper {
  margin-right: 70px;
}

.problem-container .btn-allot {
  float: right;
  margin-bottom: 15px;
  color: #fff;
  border-radius: 4px;
  line-height: 1;
  padding: 6px 10px 6px 34px;
  background: #51c78a url(@/assets/img/icon_allot.png) no-repeat 10px center;
}
</style>
